import React, { useEffect, useRef } from "react";
import { useState } from "react";
import * as Constants from "./../Constants";
import Filters from "./FiltersContainer/Filters";
import ReportTypes from "./ReportTypesContainer/ReportTypes";
import ResultList from "./PeopleContainer/Results";
import axios from "axios";
import FilterObject from "../FilterObject";

function Body(props) {
  const reportTypes = [
    { id: 1, name: "Basic" },
    { id: 2, name: "Person" },
    { id: 3, name: "Course" },
    { id: 4, name: "Facility" },
    { id: 5, name: "TODO" },
  ];

  const sendingEmail = useRef([]);
  const [sendEmail, SetSendEmail] = useState(props.email);
  const [selectedReportType, SetSelectedReportType] = useState(2);
  const [processedResultList, SetProcessedResultList] = useState([]);
  const [rerender, SetRerender] = useState(false);
  const [selectedPersonIds, SetSelectedPersonIds] = useState([]);
  const [reportTypeFilters, SetReportTypeFilters] = useState([]);
  const [reportTypeFiltersTemp, SetReportTypeFiltersTemp] = useState(null);

  function ResetFilters() {
    SetReportTypeFilters([]);
    SetReportTypeFilters([
      // #region Person Filters

      new FilterObject({
        name: "Active Certification",
        type: "checkbox",
        value1: true,
        showOn: [2],
        apiName1: "ActiveCert",
        pathArray: ["ActiveCertificate"],
      }),
      new FilterObject({
        name: "First Name",
        type: "text",
        showOn: [2],
        apiName1: "FirstName",
      }),
      new FilterObject({
        name: "Last Name",
        type: "text",
        showOn: [2],
        apiName1: "LastName",
      }),
      new FilterObject({
        name: "Country",
        type: "dropdown",
        showOn: [2],
        isCountry: true,
        apiName1: "Country",
        pathArray: ["CountryId"],
      }),
      new FilterObject({
        name: "State",
        type: "dropdown",
        value1: "",
        showOn: [2, 4],
        apiName1: "State",
        isState: true,
        pathArray: ["StateId"],
      }),
      new FilterObject({
        name: "Email",
        type: "text",
        showOn: [2],
        apiName1: "EmailAddress",
        pathArray: ["Email"],
      }),
      new FilterObject({
        name: "Phone Number",
        type: "text",
        showOn: [2],
        apiName1: "Phone",
      }),
      new FilterObject({
        name: "Certification Expires After",
        type: "date",
        showOn: [2],
        greaterThan: true,
        apiName1: "CertificationExpirationStart",
        pathArray: ["CertificationExpiration"],
      }),
      new FilterObject({
        name: "Certification Expires Before",
        type: "date",
        showOn: [2],
        lessThan: true,
        apiName1: "CertificationExpirationEnd",
        pathArray: ["CertificationExpiration"],
      }),
      new FilterObject({
        name: "Membership Expiration Start",
        type: "date",
        showOn: [2],
        greaterThan: true,
        apiName1: "MembershipExpirationStart",
        pathArray: ["MembershipExpiration"],
      }),
      new FilterObject({
        name: "Membership Expiration End",
        type: "date",
        showOn: [2],
        lessThan: true,
        apiName1: "MembershipExpirationEnd",
        pathArray: ["MembershipExpiration"],
      }),
      new FilterObject({
        name: "Packet Sent Start",
        type: "date",
        showOn: [2],
        greaterThan: true,
        apiName1: "AamsnPacketSentStart",
        pathArray: ["AamsnPacketSent"],
      }),
      new FilterObject({
        name: "Packet Sent End",
        type: "date",
        showOn: [2],
        lessThan: true,
        apiName1: "AamsnPacketSentEnd",
        pathArray: ["AamsnPacketSent"],
      }),
      new FilterObject({
        name: "Address",
        type: "text",
        showOn: [2],
        apiName1: "Address",
      }),
      new FilterObject({
        name: "License Number",
        type: "text",
        showOn: [2],
        apiName1: "LicenseNumber",
        pathArray: ["Licenses", "LicenseNumber"],
      }),
      new FilterObject({
        name: "Sedation Completed",
        type: "checkbox",
        value1: false,
        showOn: [2],
        apiName1: "SedationCompleted",
      }),
      new FilterObject({
        name: "Active Aamsn Member",
        type: "checkbox",
        value1: false,
        showOn: [2],
        apiName1: "ActiveMember",
      }),
      new FilterObject({
        name: "ACLS Certification",
        type: "checkbox",
        value1: false,
        showOn: [2],
        apiName1: "AclsCertified",
        // onlyThis: true,
      }),
      new FilterObject({
        name: "PALS Certification",
        type: "checkbox",
        value1: false,
        showOn: [2],
        apiName1: "PalsCertified",
      }),
      new FilterObject({
        name: "Do Not Email",
        type: "checkbox",
        value1: false,
        showOn: [2],
        apiName1: "DoNotEmail",
      }),
      new FilterObject({
        name: "Multiple Licenses",
        type: "checkbox",
        value1: false,
        showOn: [2],
        apiName1: "MultipleLicenses",
      }),
      new FilterObject({
        name: "New Certification",
        type: "checkbox",
        value1: false,
        reverse: true,
        showOn: [2],
        apiName1: "Recertification",
      }),
      new FilterObject({
        name: "Recertification",
        type: "checkbox",
        value1: false,
        showOn: [2],
        apiName1: "Recertification",
      }),
      new FilterObject({
        name: "Hold",
        type: "checkbox",
        value1: false,
        showOn: [2],
        apiName1: "Hold",
        pathArray: ["OnHold"],
      }),
      // new FilterObject({
      //   name: "Recertification Start",
      //   type: "date",
      //   showOn: [2],
      //   greaterThan: true,
      //   apiName1: "RecertificationStart",
      // }),
      // new FilterObject({
      //   name: "Recertification End",
      //   type: "date",
      //   showOn: [2],
      //   lessThan: true,
      //   apiName1: "RecertificationEnd",
      // }),
      // new FilterObject({
      //   name: "License Type",
      //   type: "dropdown",
      //   value1: 0,
      //   showOn: [2],
      //   apiName1: "LicenseType",
      // }),
      // new FilterObject({
      //   name: "Sedation Type",
      //   type: "dropdown",
      //   value1: 0,
      //   showOn: [2],
      //   apiName1: "SedationType",
      // }),

      // #endregion
      // #region Course Filters

      new FilterObject({
        name: "Active",
        type: "checkbox",
        value1: true,
        showOn: [3],
        apiName1: "Active",
      }),
      new FilterObject({
        name: "Course Name",
        type: "text",
        showOn: [3],
        apiName1: "Name",
      }),
      new FilterObject({
        name: "Course Type",
        type: "dropdown",
        showOn: [3],
        apiName1: "CourseType",
        pathArray: ["Type", "Id"],
      }),
      new FilterObject({
        name: "License Expires After",
        type: "date",
        showOn: [3],
        greaterThan: true,
        apiName1: "LicenseExpirationStart",
        pathArray: ["LicenseExpiration"],
      }),
      new FilterObject({
        name: "License Expires Before",
        type: "date",
        showOn: [3],
        lessThan: true,
        apiName1: "LicenseExpirationEnd",
        pathArray: ["LicenseExpiration"],
      }),
      new FilterObject({
        name: "Course Code",
        type: "text",
        showOn: [3],
        apiName1: "CourseCode",
        pathArray: ["Code"],
      }),
      new FilterObject({
        name: "State Requires Certification",
        type: "checkbox",
        value1: false,
        showOn: [3],
        apiName1: "StateRequiresCertification",
        pathArray: ["Facility", "State", "RequiresCertification"],
      }),

      // #endregion
      // #region Facility Filters

      new FilterObject({
        name: "State Requires Certification",
        type: "checkbox",
        value1: false,
        showOn: [4],
        apiName1: "StateRequiresCertification",
        pathArray: ["State", "RequiresCertification"],
      }),
      new FilterObject({
        name: "Has Active Course",
        type: "checkbox",
        value1: false,
        showOn: [4],
        apiName1: "ActiveCourse",
        pathArray: ["Courses", "Active"],
      }),
      new FilterObject({
        name: "Course Name",
        type: "text",
        showOn: [4],
        apiName1: "Name",
      }),
      new FilterObject({
        name: "Facility Name",
        type: "text",
        showOn: [4],
        apiName1: "Name",
      }),

      // #endregion
      // #region TODO Filters

      new FilterObject({
        name: "Show On Hold",
        type: "checkbox",
        value1: true,
        showOn: [5],
        apiName1: "OnHold",
      }),
      new FilterObject({
        name: "Show No Aamsn",
        type: "checkbox",
        value1: true,
        showOn: [5],
        apiName1: "NoAamsn",
      }),
      new FilterObject({
        name: "Show Courses",
        type: "checkbox",
        value1: true,
        showOn: [5],
        apiName1: "ShowCourses",
      }),
      new FilterObject({
        name: "Show Facilities",
        type: "checkbox",
        value1: true,
        showOn: [5],
        apiName1: "ShowFacilities",
      }),

      // #endregion
    ]);
  }

  function AddSendingEmail(reportType) {
    sendingEmail.current = [...sendingEmail.current, reportType];
    setTimeout(() => {
      SetRerender(reportType + "a");
    }, 300);
  }

  function RemoveSendingEmail(reportType) {
    sendingEmail.current = sendingEmail.current.filter((x) => x !== reportType);
    setTimeout(() => {
      SetRerender(reportType + "r");
    }, 300);
  }

  function FilterObjectValueChange(filterobject, value) {
    SetReportTypeFiltersTemp({ filterobject: filterobject, value: value });
  }

  function ChangeSelectedReportType(value) {
    SetSelectedReportType(value);
  }

  function GetData(envelopeReprint = false) {
    if (sendingEmail.current.includes(selectedReportType)) {
      return;
    }
    AddSendingEmail(selectedReportType);
    let params = BuildQueryParamsFromFilters();
    let reportType = reportTypes.find((r) => r.id === selectedReportType).name;

    axios({
      method: "get",
      url: Constants.API_URL + "GetReport" + Constants.API_Key,
      params: params,
      headers: {
        GetType: reportType,
      },
    }).finally(() => {
      RemoveSendingEmail(selectedReportType);
    });
  }

  async function FilterItems() {
    let filteredResultList = [];
    switch (selectedReportType) {
      case 5:
      case 2:
        filteredResultList = props.people.slice();
        break;
      case 3:
        filteredResultList = props.courses.slice();
        filteredResultList = filteredResultList.sort((a, b) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        });
        break;
      case 4:
        filteredResultList = props.facilities.slice();
        filteredResultList = filteredResultList.sort((a, b) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    let filteredFilters = reportTypeFilters.filter((f) => f.onlyThis);
    if (filteredFilters.length === 0) {
      filteredFilters = reportTypeFilters;
    }
    let exampleFiltered = {
      filter: null,
      passedObject: null,
      failedObject: null,
    };
    for (let i = 0; i < filteredFilters.length; i++) {
      filteredResultList = filteredResultList.filter((item) => {
        let result = filteredFilters[i].Matches(item, selectedReportType);
        if (
          filteredFilters[i].showOn.includes(selectedReportType) &&
          filteredFilters[i].value1 !== "" &&
          filteredFilters[i].value1 !== false
        ) {
          exampleFiltered.filter = filteredFilters[i];
          exampleFiltered[result ? "passedObject" : "failedObject"] = item;
        }
        return result;
      });
    }
    // console.log(exampleFiltered);
    filteredResultList = filteredResultList.sort((a, b) => {
      if (a.FirstName < b.FirstName) {
        return -1;
      }
      if (a.FirstName > b.FirstName) {
        return 1;
      }
      return 0;
    });
    if (selectedReportType === 5) {
      for (let i = 0; i < reportTypeFilters.length; i++) {
        if (
          reportTypeFilters[i].pathArray.includes("ShowCourses") &&
          reportTypeFilters[i].value1
        ) {
          filteredResultList = [...filteredResultList, ...props.coursesTodo];
        }
        if (
          reportTypeFilters[i].pathArray.includes("ShowFacilities") &&
          reportTypeFilters[i].value1
        ) {
          filteredResultList = [...filteredResultList, ...props.facilitiesTodo];
        }
      }
      filteredResultList = filteredResultList
        .filter((p) => p.IsOnTodoList)
        .sort((a, b) => {
          let aValue = a.SedationReceivedDate;
          aValue = aValue === undefined ? a.AddedDate : aValue;
          aValue = aValue === undefined ? a.StartDate : aValue;
          let bValue = b.SedationReceivedDate;
          bValue = bValue === undefined ? b.AddedDate : bValue;
          bValue = bValue === undefined ? b.StartDate : bValue;
          if (aValue == null || bValue == null) {
            return 0;
          }
          return new Date(bValue) - new Date(aValue);
        });
    }
    SetProcessedResultList(filteredResultList);
  }

  function BuildQueryParamsFromFilters() {
    let queryParams = {};
    const applicableFilters = reportTypeFilters.filter((f) =>
      f.showOn.includes(selectedReportType)
    );
    for (let i = 0; i < applicableFilters.length; i++) {
      if (
        applicableFilters[i].value1 !== "" &&
        applicableFilters[i].value1 !== false
      ) {
        if (selectedReportType === 4 && applicableFilters[i].apiName2 !== "") {
          queryParams[applicableFilters[i].apiName2] =
            applicableFilters[i].value1;
          continue;
        }
        queryParams[applicableFilters[i].apiName1] =
          applicableFilters[i].value1;
      }
    }
    queryParams["email"] = sendEmail;
    queryParams["EmailResults"] = true;

    return queryParams;
  }

  function HandleCheckbox(id) {
    if (!selectedPersonIds.includes(id)) {
      SetSelectedPersonIds([...selectedPersonIds, id]);
    } else {
      SetSelectedPersonIds(selectedPersonIds.filter((x) => x !== id));
    }
  }

  function SendEnvelopeReprintCSV() {}

  function ClearSelections() {
    SetSelectedPersonIds([]);
  }

  useEffect(() => {
    FilterItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportTypeFilters,
    props.people,
    selectedReportType,
    props.facilitiesTodo,
    props.coursesTodo,
  ]);

  useEffect(() => {
    if (reportTypeFiltersTemp === null) {
      return;
    }
    const timeout = setTimeout(() => {
      let temp = reportTypeFilters;
      let index = temp.indexOf(reportTypeFiltersTemp.filterobject);
      temp[index].value1 = reportTypeFiltersTemp.value;
      SetReportTypeFilters(temp);
      SetRerender(rerender + "f");
      FilterItems();
    }, 500);
    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportTypeFiltersTemp]);

  useEffect(() => {
    ResetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mainBody">
      <ReportTypes
        reportTypes={reportTypes}
        ChangeSelectedReportType={ChangeSelectedReportType}
      />
      <Filters
        rerender={rerender}
        selectedReportType={selectedReportType}
        reportTypeFilters={reportTypeFilters}
        FilterObjectValueChange={FilterObjectValueChange}
        ClientSideSearch={FilterItems}
        GetData={GetData}
        sendingEmail={sendingEmail.current}
        AddSendingEmail={AddSendingEmail}
        RemoveSendingEmail={RemoveSendingEmail}
        countries={props.countries}
        states={props.states}
        coursesDropDown={props.coursesDropDown}
        facilitiesDropDown={props.facilitiesDropDown.filter(
          (f) => f.AdditionalInfo.trim() !== ","
        )}
        sendEmail={sendEmail}
        SetSendEmail={SetSendEmail}
        people={props.people}
        reportTypes={reportTypes}
        ChangeSelectedReportType={ChangeSelectedReportType}
        disableSelectionButtons={selectedPersonIds.length === 0}
        selectedPersonIds={selectedPersonIds}
        SendEnvelopeReprintCSV={SendEnvelopeReprintCSV}
        ClearSelections={ClearSelections}
        ResetFilters={ResetFilters}
      />
      <ResultList
        processedResultList={
          [2, 3, 4, 5].includes(selectedReportType) ? processedResultList : []
        }
        people={props.people}
        rerender={rerender}
        sendEmail={sendEmail}
        SetSendEmail={SetSendEmail}
        selectedReportType={selectedReportType}
        Refresh={props.Refresh}
        currentPerson={props.currentPerson}
        OpenItemModal={props.OpenItemModal}
        courses={props.courses}
        coursesDropDown={props.coursesDropDown}
        facilities={props.facilities}
        facilitiesDropDown={props.facilitiesDropDown}
        states={props.states}
        RefreshTodo={props.RefreshTodo}
        selectedPersonIds={selectedPersonIds}
        HandleCheckbox={HandleCheckbox}
      />
    </div>
  );
}

export default Body;
