import React, { useState } from "react";
import axios from "axios";
import * as Constants from "../../Constants";
import ModalDatafield from "./ModalDatafield";

function NewSedationModal(props) {
  let today = new Date();
  const [editableFields, SetEditableFields] = useState({
    sedationFee: 225,
    sedationDiscountAmount: 0,
    sedationPurchaseDate: today.toISOString().split("T")[0],
    sedationComments: "",
    sedationFacilityId: props.person.Sedations[0].FacilityId,
  });

  function ToggleEditMode() {
    props.CloseModal();
  }

  function HandleChange(e) {
    SetEditableFields({
      ...editableFields,
      [e.target.id.replace("new-", "")]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  }

  async function AddSedation() {
    let formData = new FormData();
    formData.append("Id", props.person.Id ?? "");
    formData.append("sedation.id", 0);
    formData.append("sedation.fee", editableFields.sedationFee ?? "");
    formData.append(
      "sedation.discountAmount",
      editableFields.sedationDiscountAmount ?? ""
    );
    formData.append(
      "sedation.purchaseDate",
      editableFields.sedationPurchaseDate ?? ""
    );
    formData.append("sedation.comments", editableFields.sedationComments ?? "");
    formData.append(
      "sedation.facilityId",
      editableFields.sedationFacilityId ?? ""
    );
    let errorMessage = "";
    await axios({
      method: "post",
      url: Constants.API_URL + "Update" + Constants.API_Key,
      headers: {
        UpdateType: "Person",
      },
      data: formData,
    }).catch((error) => {
      errorMessage = error.response?.data;
      console.log(errorMessage);
      alert("Error updating person");
    });

    if (errorMessage === "" || errorMessage == null) {
      props.RefreshPerson(props.person.Id);
      props.RefreshTodo();
      props.ToggleEditing(false);
      props.CloseModal(props.person.Id);
    }

    alert("Error adding sedation");
    return false;
  }

  return (
    <div className="modal">
      <div className="modalOverlay" onClick={props.CloseModal}></div>
      <div className="modalContent">
        <div className="modalHeader">
          <div className="spacerDiv"></div>
          <div className="buttonContainer modal">
            <div></div>
            <div
              className="modalButton button"
              onClick={props.isEditing ? AddSedation : ToggleEditMode}
            >
              {props.isEditing ? "Add" : "Edit"}
            </div>
            {props.isEditing ? (
              <div className="modalButton button" onClick={ToggleEditMode}>
                Cancel
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <span className="close" onClick={props.CloseModal}>
            &times;
          </span>
        </div>
        <div className="modalBody">
          <section>
            <div className="modalBodyHeader">
              <h2>New Sedation</h2>
              <hr />
            </div>
            <div className="modalBodyContent">
              <ModalDatafield
                label="Fee"
                inputId="new-sedationFee"
                type="number"
                placeholderValue={+props.person.Sedations[0].Fee}
                editable={true}
                isEditing={props.isEditing}
                HandleChange={HandleChange}
                value={editableFields.sedationFee}
              />
              <ModalDatafield
                label="Discount Amount"
                inputId="new-sedationDiscountAmount"
                type="number"
                placeholderValue={+props.person.Sedations[0].DiscountAmount}
                editable={true}
                isEditing={props.isEditing}
                HandleChange={HandleChange}
                value={editableFields.sedationDiscountAmount}
              />
              <ModalDatafield
                label="Refund"
                inputId="new-sedationRefund"
                type="number"
                placeholderValue={+props.person.Sedations[0].Refund}
                editable={true}
                isEditing={props.isEditing}
                HandleChange={HandleChange}
                value={editableFields.sedationRefund}
              />
              <ModalDatafield
                label="Purchase Date"
                inputId="new-sedationPurchaseDate"
                type="date"
                value={editableFields.sedationPurchaseDate}
                HandleChange={HandleChange}
                editable={true}
                isEditing={props.isEditing}
              />

              <ModalDatafield
                label="Facility"
                type="dropdown"
                value={editableFields.facilityId}
                text={props.course?.Facility?.Name}
                large={true}
                additionalInfo={props.facilities
                  ?.find((f) => f.Id === editableFields.facilityId)
                  ?.AdditionalInfo.trim()}
                items={props.facilities.filter(
                  (f) =>
                    f.AdditionalInfo.trim() !== "," ||
                    f.Id === props.person.FacilityId
                )}
                HandleChange={HandleChange}
                editable={true}
                isEditing={props.isEditing}
                inputId="sedationFacilityId"
              />
              <ModalDatafield
                label="Comments"
                editable={true}
                isEditing={props.isEditing}
                type="textarea"
                fullLength={true}
                inputId="new-sedationComments"
                placeholderValue={
                  editableFields.sedationComments !== null
                    ? editableFields.sedationComments
                    : props.person.Sedations[0].Comments
                }
                value={editableFields.sedationComments}
                HandleChange={HandleChange}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default NewSedationModal;
