import React from "react";

function FilterTabs(props) {
  function ChangeType(type) {
    props.ChangeSelectedReportType(type);
  }

  return (
    <div className="filterTabs">
      <div
        className={
          "tab" + (props.selectedReportType === 2 ? " selectedTab" : "")
        }
        onClick={() => ChangeType(2)}
      >
        People
      </div>
      <div
        className={
          "tab" + (props.selectedReportType === 3 ? " selectedTab" : "")
        }
        onClick={() => ChangeType(3)}
      >
        Courses
      </div>
      <div
        className={
          "tab" + (props.selectedReportType === 4 ? " selectedTab" : "")
        }
        onClick={() => ChangeType(4)}
      >
        Facilities
      </div>
    </div>
  );
}

export default FilterTabs;
