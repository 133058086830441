import React, { useState, useEffect } from "react";
import Login from "./Login";
import Header from "./Header/Header";
import Body from "./Body/Body";
import Footer from "./Footer/Footer";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import axios from "axios";
import * as Constants from "./Constants";

function MainScreen() {
  const { instance } = useMsal();
  const msal = useMsal();
  const [refreshPeople, SetRefreshPeople] = useState(false);
  const [refreshTodo, SetRefreshTodo] = useState(false);
  const [refreshCourses, SetRefreshCourses] = useState(false);
  const [refreshFacilities, SetRefreshFacilities] = useState(false);
  const [people, SetPeople] = useState([]);
  const [countries, SetCountries] = useState([]);
  const [states, SetStates] = useState([]);
  const [courses, SetCourses] = useState([]);
  const [coursesDropDown, SetCoursesDropDown] = useState([]);
  const [coursesTodo, SetCoursesTodo] = useState([]);
  const [facilities, SetFacilities] = useState([]);
  const [facilitiesDropDown, SetFacilitiesDropDown] = useState([]);
  const [facilitiesTodo, SetFacilitiesTodo] = useState([]);
  const [email, SetEmail] = useState("");
  const [currentPerson, SetCurrentPerson] = useState(null);

  async function HandleLogin() {
    await instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  async function HandleLogout() {
    await instance.logoutRedirect().catch((e) => console.error(e));
  }

  async function GetPeople(id = "") {
    if (id === 0) {
      SetCurrentPerson(null);
    }
    if (refreshPeople || (id !== 0 && id !== "")) {
      let result = await axios({
        method: "get",
        url:
          Constants.API_URL +
          "GetReport" +
          Constants.API_Key +
          (id !== "" ? "&id=" + id : "&minimal=true"),
        headers: {
          GetType: "Person",
        },
      }).catch((e) => {
        return "Error";
      });

      ChangeRefreshPeople(false);
      if (result === "Error") {
        return;
      }
      if (id === "") {
        SetPeople(result.data);
      } else {
        if (result.data.length > 0) {
          result.data[0].Sedations.sort((a, b) => {
            return new Date(b.TestReceivedDate) - new Date(a.TestReceivedDate);
          });
          SetCurrentPerson(result.data[0]);
        }
      }
    }
  }

  async function GetCourses() {
    if (refreshCourses) {
      let result = await axios({
        method: "get",
        url: Constants.API_URL + "GetReport" + Constants.API_Key,
        headers: {
          GetType: "Course",
        },
      }).catch((e) => {
        return "Error";
      });

      ChangeRefreshCourses(false);

      if (result === "Error") {
        return;
      }

      SetCourses(result.data);
    }
  }

  async function GetFacilities() {
    if (refreshFacilities) {
      let result = await axios({
        method: "get",
        url: Constants.API_URL + "GetReport" + Constants.API_Key,
        headers: {
          GetType: "Facility",
        },
      }).catch((e) => {
        return "Error";
      });

      ChangeRefreshFacilities(false);

      if (result === "Error") {
        return;
      }

      SetFacilities(result.data);
    }
  }

  async function GetTodoList() {
    if (refreshTodo) {
      let facilityResult = await axios({
        method: "get",
        url:
          Constants.API_URL +
          "GetReport" +
          Constants.API_Key +
          "&OnlyTodo=true",
        headers: {
          GetType: "Facility",
        },
      }).catch((e) => {
        return "Error";
      });

      let courseResult = await axios({
        method: "get",
        url:
          Constants.API_URL +
          "GetReport" +
          Constants.API_Key +
          "&OnlyTodo=true",
        headers: {
          GetType: "Course",
        },
      }).catch((e) => {
        return "Error";
      });

      SetRefreshTodo(false);

      if (facilityResult !== "Error") {
        SetFacilitiesTodo(facilityResult.data);
      }
      if (courseResult !== "Error") {
        SetCoursesTodo(courseResult.data);
      }
    }
  }

  function ChangeRefreshPeople(value) {
    SetRefreshPeople(value);
  }

  function ChangeRefreshCourses(value) {
    SetRefreshCourses(value);
  }

  function ChangeRefreshFacilities(value) {
    SetRefreshFacilities(value);
  }

  function ChangeRefreshTodo(value) {
    SetRefreshTodo(value);
  }

  function GetDropDownItems() {
    if (refreshPeople) {
      axios({
        method: "get",
        url: Constants.API_URL + "GetDropdownItems" + Constants.API_Key,
      }).then((response) => {
        SetCountries(response.data.Countries);
        SetStates(response.data.States);
        SetCoursesDropDown(response.data.Courses);
        SetFacilitiesDropDown(response.data.Facilities);
      });
    }
  }

  useEffect(() => {
    let refreshInterval = null;
    if (msal.accounts != null && msal.accounts.length > 0) {
      ChangeRefreshPeople(true);
      ChangeRefreshCourses(true);
      ChangeRefreshFacilities(true);
      ChangeRefreshTodo(true);
      SetEmail(msal.accounts[0].username);
      refreshInterval = setInterval(() => {
        ChangeRefreshPeople(true);
        ChangeRefreshTodo(true);
        ChangeRefreshCourses(true);
        ChangeRefreshFacilities(true);
      }, 600000);
    }

    return () => {
      clearInterval(refreshInterval);
    };
  }, [msal.accounts]);

  useEffect(() => {
    GetDropDownItems();
    GetTodoList();
    GetPeople();
    GetCourses();
    GetFacilities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPeople, refreshTodo]);

  return (
    <div className="mainScreen">
      <Header />
      <AuthenticatedTemplate>
        <Body
          people={people}
          countries={countries}
          states={states}
          courses={courses}
          coursesDropDown={coursesDropDown}
          coursesTodo={coursesTodo}
          facilities={facilities}
          facilitiesDropDown={facilitiesDropDown}
          facilitiesTodo={facilitiesTodo}
          Logout={HandleLogout}
          email={email}
          Refresh={GetPeople}
          currentPerson={currentPerson}
          RefreshCourses={ChangeRefreshCourses}
          RefreshFacilities={ChangeRefreshFacilities}
          RefreshTodo={ChangeRefreshTodo}
        />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login Login={HandleLogin} />
      </UnauthenticatedTemplate>
      <Footer />
    </div>
  );
}

export default MainScreen;
