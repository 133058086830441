import axios from "axios";
import React from "react";
import * as Constants from "./../../Constants";
import { Icon } from "@iconify/react";

function Basic(props) {
  function GetReportOnClick(e) {
    if (props.sendingEmail.includes(e.target.id)) {
      return;
    }
    props.AddSendingEmail(e.target.id);
    axios({
      method: "get",
      url:
        Constants.API_URL +
        "GetReport" +
        Constants.API_Key +
        "&email=" +
        props.sendEmail,
      headers: {
        GetType: e.target.id,
      },
    })
      .then((response) => {
        if (response.data === "Accepted") {
          alert("Email sent");
        } else {
          alert("Error sending email");
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Error sending email");
      })
      .finally(() => {
        props.RemoveSendingEmail(e.target.id);
      });
  }

  return (
    <div className="basicContainer">
      <div className="buttonsContainer">
        <div
          className="selectableRow basicButton"
          id="MailChimpRecertOnly"
          onClick={GetReportOnClick}
        >
          {props.sendingEmail.includes("MailChimpRecertOnly") ? (
            <Icon icon="line-md:loading-loop" className="loadingSpinner" />
          ) : (
            "Mail Chimp Recert Only"
          )}
        </div>
        <div
          className="selectableRow basicButton"
          id="MailChimpEmailAAMSNDues"
          onClick={GetReportOnClick}
        >
          {props.sendingEmail.includes("MailChimpEmailAAMSNDues") ? (
            <Icon icon="line-md:loading-loop" className="loadingSpinner" />
          ) : (
            "Mail Chimp Email AAMSN Dues"
          )}
        </div>
        <div
          className="selectableRow basicButton"
          id="DuesAamsn"
          onClick={GetReportOnClick}
        >
          {props.sendingEmail.includes("DuesAamsn") ? (
            <Icon icon="line-md:loading-loop" className="loadingSpinner" />
          ) : (
            "Dues AAMSN"
          )}
        </div>
        <div
          className="selectableRow basicButton"
          id="DuesRecertOnly"
          onClick={GetReportOnClick}
        >
          {props.sendingEmail.includes("DuesRecertOnly") ? (
            <Icon icon="line-md:loading-loop" className="loadingSpinner" />
          ) : (
            "Dues Recert Only"
          )}
        </div>
        <div
          className="selectableRow basicButton"
          id="AllSedationCerts"
          onClick={GetReportOnClick}
        >
          {props.sendingEmail.includes("AllSedationCerts") ? (
            <Icon icon="line-md:loading-loop" className="loadingSpinner" />
          ) : (
            "All Sedation Certs"
          )}
        </div>
        <div
          className="selectableRow basicButton"
          id="AllExpiredSedationCerts"
          onClick={GetReportOnClick}
        >
          {props.sendingEmail.includes("AllExpiredSedationCerts") ? (
            <Icon icon="line-md:loading-loop" className="loadingSpinner" />
          ) : (
            "All Expired Sedation Certs"
          )}
        </div>
        <div
          className="selectableRow basicButton"
          id="AAMSNActive"
          onClick={GetReportOnClick}
        >
          {props.sendingEmail.includes("AAMSNActive") ? (
            <Icon icon="line-md:loading-loop" className="loadingSpinner" />
          ) : (
            "AAMSN Active"
          )}
        </div>
        <div
          className="selectableRow basicButton"
          id="AAMSNExpired"
          onClick={GetReportOnClick}
        >
          {props.sendingEmail.includes("AAMSNExpired") ? (
            <Icon icon="line-md:loading-loop" className="loadingSpinner" />
          ) : (
            "AAMSN Expired"
          )}
        </div>
        <div
          className="selectableRow basicButton"
          id="CertificatePrint"
          onClick={GetReportOnClick}
        >
          {props.sendingEmail.includes("CertificatePrint") ? (
            <Icon icon="line-md:loading-loop" className="loadingSpinner" />
          ) : (
            "Certificate Print"
          )}
        </div>
        <div
          className="selectableRow basicButton"
          id="FacilityEnvelope"
          onClick={GetReportOnClick}
        >
          {props.sendingEmail.includes("FacilityEnvelope") ? (
            <Icon icon="line-md:loading-loop" className="loadingSpinner" />
          ) : (
            "Facility Envelope"
          )}
        </div>
      </div>
    </div>
  );
}

export default Basic;
