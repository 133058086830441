import React from "react";
import * as Utilities from "../../Utilities";

function ModalDatafield(props) {
  return (
    <div
      className={
        (props.fullLength === true
          ? "modalFullLengthColumn"
          : "modalBodyColumn") +
        (props.type === "dropdown" && props.large
          ? " largeModalDropdown"
          : "") +
        (props.manualRecert !== undefined ? " withButton" : "")
      }
    >
      {props.type === "date" ? (
        props.editable && props.isEditing ? (
          <>
            <label htmlFor={props.inputId} className="modalLabel">
              {props.label + ": "}
            </label>
            <input
              type={props.type}
              id={props.inputId}
              className={
                "modalInput itemInput" +
                (props.highlighted ? " highlighted" : "")
              }
              value={props.value.split("T")[0]}
              onChange={props.HandleChange}
            ></input>
          </>
        ) : (
          <>
            <span className="modalLabel">{props.label + ": "} </span>
            <span
              className={
                "modalBodyValue" + (props.highlighted ? " highlighted" : "")
              }
            >
              {props.value
                ? Utilities.ToLocalDateStringNoTimezone(props.value)
                : ""}
            </span>
          </>
        )
      ) : props.type === "checkbox" ? (
        props.editable && props.isEditing ? (
          <>
            <label htmlFor={props.inputId} className="modalLabel">
              {props.label}
            </label>
            <input
              type={props.type}
              id={props.inputId}
              checked={props.value}
              onChange={props.HandleChange}
            ></input>
          </>
        ) : (
          <>
            <span className="modalLabel">{props.label + ": "} </span>
            <span
              className={
                "modalBodyValue" + (props.highlighted ? " highlighted" : "")
              }
            >
              {props.value ? "Yes" : "No"}
            </span>
          </>
        )
      ) : props.type === "textarea" ? (
        <>
          <label
            htmlFor={props.inputId}
            className={"modalLabel" + (props.highlighted ? " highlighted" : "")}
          >
            {props.label}
          </label>
          <textarea
            id={props.inputId}
            className={
              "modalTextArea" + (props.highlighted ? " highlighted" : "")
            }
            value={props.value || ""}
            onChange={props.HandleChange}
            placeholder={props.placeholderValue}
            readOnly={!props.isEditing}
          ></textarea>
        </>
      ) : props.type === "number" && props.editable && props.isEditing ? (
        <>
          <label htmlFor={props.inputId} className="modalLabel">
            {props.label}
          </label>
          <input
            type={props.type}
            id={props.inputId}
            className={
              "modalInput itemInput" + (props.highlighted ? " highlighted" : "")
            }
            value={props.value}
            onChange={props.HandleChange}
            readOnly={!props.isEditing}
            placeholder={props.placeholderValue}
          ></input>
        </>
      ) : props.type === "sentButton" ? (
        <>
          <span className="modalLabel">{props.label + ": "} </span>

          {props.value !== null && props.value !== "" && !props.forceShow ? (
            Utilities.ToLocalDateStringNoTimezone(props.value)
          ) : (
            <div className={"modalBodyColumn withButton"}>
              <span className={"modalBodyValue"}>
                {props.value
                  ? Utilities.ToLocalDateStringNoTimezone(props.value)
                  : "Not Sent"}
              </span>
              <div
                className={"modalButton button highlighted"}
                id={props.inputId}
                onClick={props.HandleChange}
              >
                Mark as sent today
              </div>
            </div>
          )}
        </>
      ) : props.type === "dropdown" ? (
        props.isEditing ? (
          <>
            <label htmlFor={props.inputId} className="modalLabel">
              {props.label}
            </label>
            <select
              className={
                "modalInput itemInput" +
                (props.large ? " largeInput" : "") +
                (props.highlighted ? " highlighted" : "")
              }
              id={props.inputId}
              onChange={props.HandleChange}
              value={props.value}
            >
              <option key={-1} value={0}>
                {"No Facility"}
              </option>
              {props.items?.map((item, index) => (
                <option key={index} value={item.Id}>
                  {props.large === true &&
                  item.AdditionalInfo &&
                  item.AdditionalInfo.trim() !== ","
                    ? item.Name + " - " + item.AdditionalInfo
                    : item.Name}
                </option>
              ))}
            </select>
          </>
        ) : (
          <>
            <span className="modalLabel">{props.label + ": "} </span>
            <span
              className={
                "modalBodyValue" + (props.highlighted ? " highlighted" : "")
              }
            >
              {props.large === true
                ? props.additionalInfo && props.additionalInfo !== ","
                  ? props.text + " - " + props.additionalInfo
                  : props.text
                : props.text}
            </span>
          </>
        )
      ) : props.type === "text" && props.isEditing ? (
        <>
          <label htmlFor={props.inputId} className="modalLabel">
            {props.label}
          </label>
          <input
            type={props.type}
            id={props.inputId}
            className={
              "modalInput itemInput" +
              (props.large ? " largeInput" : "") +
              (props.highlighted ? " highlighted" : "")
            }
            value={props.value}
            onChange={props.HandleChange}
          />
        </>
      ) : props.type === "certificateExpirationDate" ? (
        <>
          <span className="modalLabel">{props.label + ": "} </span>
          <div
            className={
              "modalBodyColumn withButton" +
              (props.highlighted ? " highlighted" : "")
            }
          >
            <span
              className={
                "modalBodyValue" + (props.highlighted ? " highlighted" : "")
              }
            >
              {props.value
                ? Utilities.ToLocalDateStringNoTimezone(props.value)
                : ""}
            </span>
            {props.manualRecert ? (
              <div className="modalButton button" onClick={props.HandleChange}>
                Add Manual Recert
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <span
            className={"modalLabel" + (props.highlighted ? " highlighted" : "")}
          >
            {props.label + ": "}{" "}
          </span>
          <span
            className={
              "modalBodyValue" + (props.highlighted ? " highlighted" : "")
            }
          >
            {props.value}
          </span>
        </>
      )}
    </div>
  );
}

export default ModalDatafield;
