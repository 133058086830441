import React from "react";

function Login(props) {
  return (
    <div className="mainLogin">
      <div className="container login">
        <div className="header login">
          <div className="title login">Heathy Visions Reporting</div>
        </div>
        <div className="footer login">
          <button className="button login" onClick={() => props.Login()}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
