import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../Constants";
import * as Utilities from "../../Utilities";
import ModalDatafield from "./ModalDatafield";

function FacilityModal(props) {
  const [editableFields, SetEditableFields] = useState({
    address1: props.facility?.Address1,
    address2: props.facility?.Address2,
    city: props.facility?.City,
    stateId: props.facility?.StateId,
    zip: props.facility?.Zip,
    verified: props.facility?.Verified,
  });

  function ToggleEditMode() {
    resetFields();
    props.ToggleEditing();
  }

  function HandleChange(e) {
    SetEditableFields({
      ...editableFields,
      [e.target.id]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  }

  function resetFields() {
    SetEditableFields({
      address1: props.facility.Address1,
      address2: props.facility.Address2,
      city: props.facility.City,
      stateId: props.facility.StateId,
      zip: props.facility.Zip,
      verified: props.facility.Verified,
    });
  }

  async function UpdateFacility() {
    let formData = new FormData();
    formData.append("Id", props.facility.Id ?? "");
    formData.append("facility.address1", editableFields.address1 ?? "");
    formData.append("facility.address2", editableFields.address2 ?? "");
    formData.append("facility.city", editableFields.city ?? "");
    formData.append("facility.stateId", editableFields.stateId ?? "");
    formData.append("facility.zip", editableFields.zip ?? "");
    formData.append("facility.verified", editableFields.verified ?? "");
    let errorMessage = "";
    await axios({
      method: "post",
      url: Constants.API_URL + "Update" + Constants.API_Key,
      headers: {
        UpdateType: "Facility",
      },
      data: formData,
    }).catch((error) => {
      errorMessage = error.response.data;
      alert("Error updating facility");
    });
    if (errorMessage === "") {
      await props.RefreshTodo(true);
      props.ToggleEditing();
    }
  }

  useEffect(() => {
    resetFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.facility]);

  return (
    <div className="modal">
      <div className="modalOverlay" onClick={props.CloseModal}></div>
      <div className="modalContent">
        <div className="modalHeader">
          <div className="spacerDiv"></div>
          <div className="buttonContainer modal">
            <div></div>
            <div
              className="modalButton button"
              onClick={props.isEditing ? UpdateFacility : ToggleEditMode}
            >
              {props.isEditing ? "Save" : "Edit"}
            </div>
            {props.isEditing ? (
              <div className="modalButton button" onClick={ToggleEditMode}>
                Cancel
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <span className="close" onClick={props.CloseModal}>
            &times;
          </span>
        </div>
        <div className="modalBody">
          <section>
            <div className="modalBodyHeader">
              <h2>Facility Information</h2>
              <hr />
            </div>
            <div className="modalBodyContent">
              <ModalDatafield
                label="Name"
                value={props.facility.Name}
                type="text"
              />
              <ModalDatafield
                label="Verified"
                value={editableFields.verified}
                type="checkbox"
                HandleChange={HandleChange}
                inputId="verified"
                editable={true}
                isEditing={props.isEditing}
              />
              <ModalDatafield
                label="Date Added"
                value={
                  props.facility.DateAdded !== null &&
                  props.facility.DateAdded !== 0
                    ? Utilities.ToLocalDateStringNoTimezone(
                        props.facility.DateAdded
                      )
                    : "N/A"
                }
                type="text"
              />
              <ModalDatafield
                label="Address 1"
                value={editableFields.address1}
                type="text"
                editable={true}
                isEditing={props.isEditing}
                inputId="address1"
                HandleChange={HandleChange}
              />
              <ModalDatafield
                label="Address 2"
                value={editableFields.address2}
                type="text"
                editable={true}
                isEditing={props.isEditing}
                inputId="address2"
                HandleChange={HandleChange}
              />
              <ModalDatafield
                label="City"
                value={editableFields.city}
                type="text"
                editable={true}
                isEditing={props.isEditing}
                inputId="city"
                HandleChange={HandleChange}
              />
              <ModalDatafield
                label="State"
                value={editableFields.stateId}
                type="dropdown"
                text={props.facility?.State?.Name}
                large={false}
                additionalInfo={null}
                items={props.states}
                HandleChange={HandleChange}
                editable={true}
                isEditing={props.isEditing}
                inputId="stateId"
              />
              <ModalDatafield
                label="Zip Code"
                value={editableFields.zip}
                type="text"
                editable={true}
                isEditing={props.isEditing}
                inputId="zip"
                HandleChange={HandleChange}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default FacilityModal;
