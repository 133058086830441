import React from "react";

function ReportTypes(props) {
  return (
    <div className="container reportTypes">
      <div
        className="selectableRow"
        onClick={() => props.ChangeSelectedReportType(2)}
      >
        <div className="name reportTypes">Search</div>
      </div>
      {props.reportTypes
        .filter((reportType) => ![0, 2, 3, 4].includes(reportType.id))
        .map((reportType) => (
          <div
            key={reportType.id}
            className="selectableRow"
            onClick={() => props.ChangeSelectedReportType(reportType.id)}
          >
            <div className="name reportTypes">{reportType.name}</div>
          </div>
        ))}
    </div>
  );
}

export default ReportTypes;
