import React, { useRef, forwardRef } from "react";
import { Icon } from "@iconify/react";

function Person(props, ref) {
  const checkboxRef = useRef(null);

  function HandleClick(e) {
    if (
      e.target === checkboxRef.current ||
      checkboxRef.current?.contains(e.target)
    ) {
      props.HandleCheckbox(props.person?.Id);
      return;
    }
    props.OpenModal(props.person?.Id);
  }

  return (
    <div
      className={
        "selectableRow resultItem person" +
        (props.person.OnHold
          ? " hold"
          : props.person.NoAamsn
          ? " noAamsn"
          : "") +
        (props.selectedIds?.includes(props.person.Id) ? " selected" : "")
      }
      onClick={HandleClick}
      ref={props.index === props.page * 50 - 10 || props.lastItem ? ref : null}
    >
      {props.selectedReportType === 2 ? (
        <div className="checkboxContainer" ref={checkboxRef}>
          <Icon
            className="checkbox"
            icon={
              props.selectedIds?.includes(props.person.Id)
                ? "mdi:checkbox-outline"
                : "mdi:checkbox-blank-outline"
            }
          />
        </div>
      ) : (
        <div className="name bold">P</div>
      )}
      <div className="name people bold">{props.person.FirstName}</div>
      <div className="name people bold">{props.person.LastName}</div>
      <div className="name people">{props.person.State.Name}</div>
      <div className="name people">{props.person.Phone}</div>
      <div className="name people">
        {props.person.Licenses != null &&
        props.person.Licenses.length > 0 &&
        props.person.Licenses.sort((a, b) => b.Id - a.Id)[0].LicenseNumber !==
          ""
          ? props.person.Licenses.sort((a, b) => b.Id - a.Id)[0].LicenseNumber
          : "No License Number"}
      </div>
    </div>
  );
}
export default forwardRef(Person);
