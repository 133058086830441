class FilterObject {
  constructor({
    name = "",
    type = "text",
    alwaysActive = false,
    value1 = null,
    reverse = false,
    showOn = [],
    isState = false,
    isCountry = false,
    apiName1 = null,
    apiName2 = null,
    pathArray = null,
    greaterThan = false,
    lessThan = false,
    debugging = false,
    onlyThis = false,
  }) {
    this.name = name;
    this.type = type;
    this.alwaysActive = alwaysActive;
    this.value1 = value1 ?? "";
    this.reverse = reverse;
    this.showOn = showOn;
    this.isState = isState;
    this.isCountry = isCountry;
    this.apiName1 = apiName1;
    this.apiName2 = apiName2;
    this.pathArray = pathArray ?? [apiName1];
    this.greaterThan = greaterThan;
    this.lessThan = lessThan;
    this.debugging = debugging;
    this.onlyThis = onlyThis;
  }

  Matches(object, reportType) {
    if (!this.showOn.includes(reportType)) {
      return true;
    }
    if (!this.alwaysActive && (this.value1 == null || this.value1 === false)) {
      return true;
    }
    if (this.value1 == null || this.value1 === "") {
      return true;
    }

    if (this.pathArray.length === 0) {
      return true;
    }

    let value = this.RecursivelyCheckPathArray(object, this.pathArray.slice());
    // if (this.debugging) {
    //   console.log("value", value);
    // }
    return value;
  }

  RecursivelyCheckPathArray(currentValue, currentPathArray) {
    if (currentValue?.sort != null) {
      let anyMatchInside = false;
      for (let i = 0; i < currentValue.length; i++) {
        if (this.RecursivelyCheckPathArray(currentValue[i], currentPathArray)) {
          anyMatchInside = true;
          break;
        }
      }
      return anyMatchInside;
    }
    if (currentPathArray.length === 1) {
      return this.CompareValue(currentValue[currentPathArray[0]]);
    }
    if (currentValue == null) {
      return currentValue;
    }
    currentValue = currentValue[currentPathArray[0]];
    currentPathArray = currentPathArray.slice(1);
    return this.RecursivelyCheckPathArray(currentValue, currentPathArray, true);
  }

  CompareValue(value) {
    let result = true;
    // if (this.debugging) {
    //   console.log("value", value, "this.value1", this.value1);
    // }
    switch (this.type) {
      case "text":
        return value?.toLowerCase().includes(this.value1?.toLowerCase());
      case "number":
        return value === this.value1;
      case "dropdown":
        if (this.value1 === -1) {
          return true;
        }
        return value?.toString() === this.value1?.toString();
      case "date":
        let valueDate = new Date(value);
        let value1Date = new Date(this.value1);
        if (this.greaterThan) {
          return valueDate >= value1Date;
        }
        if (this.lessThan) {
          return valueDate <= value1Date;
        }
        return valueDate === value1Date;
      case "checkbox":
        if (this.reverse) {
          return value !== this.value1;
        }
        return value === this.value1;
      default:
        break;
    }
    return result;
  }
}

export default FilterObject;
