import React, { useEffect, useState } from "react";

function Filter(props) {
  const [currentValue, SetCurrentValue] = useState(props.filterObject.value1);

  async function ChangeValue1(e) {
    if (e.target.type === "checkbox") {
      props.FilterObjectValueChange(props.filterObject, !currentValue);
      SetCurrentValue(!currentValue);
      return;
    }
    props.FilterObjectValueChange(props.filterObject, e.target.value, false);
    SetCurrentValue(e.target.value);
  }

  useEffect(() => {
    SetCurrentValue(props.filterObject.value1);
  }, [props.filterObject.value1]);

  return (
    <div className={"filterItem " + props.filterObject.type}>
      {props.filterObject.type === "dropdown" ? (
        <>
          <div className={"itemTitle " + props.filterObject.type}>
            {props.filterObject.name}
          </div>
          <select
            value={currentValue}
            onChange={ChangeValue1}
            className="itemInput dropdown"
          >
            <option value="">{props.filterObject.name}</option>
            {props.filterObject.isState ? (
              props.states?.map((item, index) => (
                <option key={index} value={item.Id}>
                  {item.Name}
                </option>
              ))
            ) : props.filterObject.isCountry ? (
              props.countries?.map((item, index) => (
                <option key={index} value={item.Id}>
                  {item.Name}
                </option>
              ))
            ) : props.filterObject.apiName1 === "CourseType" ? (
              <>
                <option value={1}>Both</option>
                <option value={2}>Licensed</option>
                <option value={3}>Live</option>
              </>
            ) : null}
          </select>
        </>
      ) : (
        <div className={"itemInput " + props.filterObject.type}>
          <div className={"itemTitle " + props.filterObject.type}>
            {props.filterObject.name}
          </div>
          {props.filterObject.type === "text" ? (
            <input
              type={props.filterObject.type}
              onChange={ChangeValue1}
              placeholder={props.filterObject.name}
              value={currentValue}
            />
          ) : props.filterObject.type === "checkbox" ? (
            <input
              type={props.filterObject.type}
              onChange={ChangeValue1}
              placeholder={props.filterObject.name}
              checked={currentValue}
            />
          ) : props.filterObject.type === "date" ? (
            <div className="itemInput singleDate">
              <input
                type="date"
                className="dateTime"
                onChange={ChangeValue1}
                value={currentValue}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default Filter;
