import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../Constants";
import ModalDatafield from "./ModalDatafield";

function CourseModal(props) {
  const [editableFields, SetEditableFields] = useState({
    facilityId: props.course?.FacilityId,
    typeId: props.course?.TypeId,
    participantsAllowed: props.course?.ParticipantsAllowed,
    participated: props.course?.Participated,
    participatedOnline: props.course?.ParticipatedOnline,
    sendCertificatesToId: props.course?.SendCertificatesToId,
    finalCount: props.course?.FinalCount,
    comments: props.course?.Comments,
    title: props.course?.Title,
    accessCode: props.course?.AccessCode,
    sendCertificateNotes: props.course?.SendCertificateNotes,
    sedationCount: props.course?.SedationCount,
  });

  const courseTypes = [
    { Id: 0, Name: "NoneSelected" },
    { Id: 1, Name: "Both" },
    { Id: 2, Name: "Licensed" },
    { Id: 3, Name: "Live" },
  ];

  const sendCertificateToDestinations = [
    {
      Id: 0,
      Name: "NoneSelected",
    },
    {
      Id: 1,
      Name: "Facilitator",
    },
    { Id: 2, Name: "Facility" },
    { Id: 3, Name: "Particiapants" },
    { Id: 4, Name: "Participant" },
    { Id: 5, Name: "Participants" },
    { Id: 6, Name: "Sarah" },
    { Id: 7, Name: "Send" },
  ];

  function ToggleEditMode() {
    resetFields();
    props.ToggleEditing();
  }

  function HandleChange(e) {
    SetEditableFields({
      ...editableFields,
      [e.target.id]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  }

  function resetFields() {
    SetEditableFields({
      facilityId: props.course?.FacilityId,
      typeId: props.course?.TypeId,
      participantsAllowed: props.course?.ParticipantsAllowed,
      participated: props.course?.Participated,
      participatedOnline: props.course?.ParticipatedOnline,
      sendCertificatesToId: props.course?.SendCertificatesToId,
      finalCount: props.course?.FinalCount,
      comments: props.course?.Comments,
      title: props.course?.Title,
      accessCode: props.course?.AccessCode,
      sendCertificateNotes: props.course?.SendCertificateNotes,
      sedationCount: props.course?.SedationCount,
    });
  }

  async function UpdateCourse() {
    let formData = new FormData();
    formData.append("Id", props.course.Id ?? "");
    formData.append("course.facilityId", editableFields.facilityId ?? "");
    formData.append("course.typeId", editableFields.typeId ?? "");
    formData.append("course.amountPaid", editableFields.amountPaid ?? "");
    formData.append(
      "course.participantsAllowed",
      editableFields.participantsAllowed ?? ""
    );
    formData.append("course.participated", editableFields.participated ?? "");
    formData.append(
      "course.sendCertificatesToId",
      editableFields.sendCertificatesToId ?? ""
    );
    formData.append("course.finalCount", editableFields.finalCount ?? "");
    formData.append("course.comments", editableFields.comments ?? "");
    formData.append("course.title", editableFields.title ?? "");
    formData.append("course.accessCode", editableFields.accessCode ?? "");
    formData.append(
      "course.sendCertificateNotes",
      editableFields.sendCertificateNotes ?? ""
    );
    let errorMessage = "";
    await axios({
      method: "post",
      url: Constants.API_URL + "Update" + Constants.API_Key,
      headers: {
        UpdateType: "Course",
      },
      data: formData,
    }).catch((error) => {
      errorMessage = error.response.data;
      alert("Error updating course");
    });
    if (errorMessage === "") {
      props.RefreshTodo(true);
      props.ToggleEditing();
    }
  }

  useEffect(() => {
    resetFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.course]);

  return (
    <div className="modal">
      <div className="modalOverlay" onClick={props.CloseModal}></div>
      <div className="modalContent">
        <div className="modalHeader">
          <div className="spacerDiv"></div>
          <div className="buttonContainer modal">
            <div></div>
            <div
              className="modalButton button"
              onClick={props.isEditing ? UpdateCourse : ToggleEditMode}
            >
              {props.isEditing ? "Save" : "Edit"}
            </div>
            {props.isEditing ? (
              <div className="modalButton button" onClick={ToggleEditMode}>
                Cancel
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <span className="close" onClick={props.CloseModal}>
            &times;
          </span>
        </div>
        <div className="modalBody">
          <section>
            <div className="modalBodyHeader">
              <h2>Course Information</h2>
              <hr />
            </div>
            <div className="modalBodyContent">
              <ModalDatafield
                label="Name"
                value={props.course.Name}
                type="text"
              />
              <ModalDatafield
                label="Code"
                value={props.course.Code}
                type="text"
              />
              <ModalDatafield
                label="Start Date"
                type="date"
                value={props.course?.StartDate}
                inputId="startDate"
              />
              <ModalDatafield
                label="License Expiration"
                type="date"
                value={props.course?.LicenseExpiration}
                inputId="licenseExpiration"
              />
              <ModalDatafield
                type="text"
                label="Title"
                value={editableFields.title}
                HandleChange={HandleChange}
                editable={true}
                isEditing={props.isEditing}
                inputId="title"
                large={true}
              />
              <ModalDatafield
                type="dropdown"
                label="Course Type"
                value={editableFields.typeId}
                text={props.course?.Type?.Name}
                inputId="typeId"
                editable={true}
                isEditing={props.isEditing}
                items={courseTypes}
                HandleChange={HandleChange}
                additionalInfo={null}
              />
              <ModalDatafield
                label="Facility"
                type="dropdown"
                value={editableFields.facilityId}
                text={props.course?.Facility?.Name}
                large={true}
                additionalInfo={props.facilities
                  ?.find((f) => f.Id === editableFields.facilityId)
                  ?.AdditionalInfo.trim()}
                items={props.facilities.filter(
                  (f) =>
                    f.AdditionalInfo.trim() !== "," ||
                    f.Id === props.course.FacilityId
                )}
                HandleChange={HandleChange}
                editable={true}
                isEditing={props.isEditing}
                inputId="facilityId"
              />
              <ModalDatafield
                type="checkbox"
                label="Active"
                editable={false}
                value={props.course.Active}
              />
              <ModalDatafield
                type="number"
                label="Amount Paid"
                value={props.course?.AmountPaid}
              />
              <ModalDatafield
                type="number"
                label="Participants Allowed"
                editable={true}
                isEditing={props.isEditing}
                value={editableFields.participantsAllowed}
                inputId="participantsAllowed"
                HandleChange={HandleChange}
              />
              <ModalDatafield
                type="number"
                label="Participated"
                value={
                  +props.course?.Participated !== 0
                    ? props.course?.Participated
                    : props.course?.SedationCount
                }
              />
              <ModalDatafield
                type="dropdown"
                label="Send Certificates To"
                editable={true}
                isEditing={props.isEditing}
                value={editableFields.sendCertificatesToId}
                inputId="sendCertificatesToId"
                HandleChange={HandleChange}
                items={sendCertificateToDestinations}
                additionalInfo={null}
                text={props.course?.SendCertificatesTo?.Name}
              />
              <ModalDatafield
                type="text"
                label="Notes"
                editable={true}
                isEditing={props.isEditing}
                value={editableFields.sendCertificateNotes}
                large={true}
                HandleChange={HandleChange}
                inputId="sendCertificateNotes"
              />
              <ModalDatafield
                type="text"
                label="Access Code"
                editable={true}
                isEditing={props.isEditing}
                value={editableFields.accessCode}
                inputId="accessCode"
                HandleChange={HandleChange}
              />
              <ModalDatafield
                type="text"
                label="Primary Contact"
                editable={false}
                value={
                  props.course?.ContactPrimary?.FirstName +
                  " " +
                  props.course?.ContactPrimary?.LastName
                }
              />
              <ModalDatafield
                type="text"
                label="Primary Email"
                editable={false}
                value={props.course?.ContactPrimary?.Email}
              />
              <ModalDatafield
                type="textarea"
                fullLength={true}
                label="Comments"
                editable={true}
                isEditing={props.isEditing}
                value={editableFields.comments || ""}
                inputId="comments"
                HandleChange={HandleChange}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default CourseModal;
