import React from "react";
import Basic from "./Basic";
import Filter from "./Filter";
import FilterTabs from "./FilterTabs";
import axios from "axios";
import * as Constants from "../../Constants";
import { Icon } from "@iconify/react";

function Filters(props) {
  function ButtonAction() {
    if (props.selectedReportType === 0) {
      props.ClientSideSearch();
      return;
    }
    props.GetData();
  }

  function ChangeSendEmail(e) {
    props.SetSendEmail(e.target.value);
  }

  function SendEnvelopeReprintCSV() {
    if (props.sendingEmail.includes("EnvelopeReprint")) {
      return;
    }
    props.AddSendingEmail("EnvelopeReprint");
    axios({
      method: "get",
      url:
        Constants.API_URL +
        "GetReport" +
        Constants.API_Key +
        "&email=" +
        props.sendEmail +
        "&ids=" +
        props.selectedPersonIds.join("-"),
      headers: {
        GetType: "EnvelopeReprint",
      },
    })
      .then((response) => {
        if (response.data === "Accepted") {
          alert("Email sent");
        } else {
          alert("Error sending email");
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Error sending email");
      })
      .finally(() => {
        props.RemoveSendingEmail("EnvelopeReprint");
      });
  }

  function ClearSelections() {
    props.ClearSelections();
  }

  return (
    <div className="filterContainer">
      {[2, 3, 4].includes(props.selectedReportType) ? (
        <FilterTabs
          ChangeSelectedReportType={props.ChangeSelectedReportType}
          selectedReportType={props.selectedReportType}
        />
      ) : null}
      <div className="resetFiltersButton" onClick={props.ResetFilters}>
        X
      </div>
      {props.selectedReportType === 1 ? (
        <Basic
          sendEmail={props.sendEmail}
          sendingEmail={props.sendingEmail}
          AddSendingEmail={props.AddSendingEmail}
          RemoveSendingEmail={props.RemoveSendingEmail}
        />
      ) : (
        <div className="filters">
          {props.reportTypeFilters
            .filter((f) => f.showOn.includes(props.selectedReportType))
            .map((filter, index) => (
              <Filter
                key={index}
                rerender={props.rerender}
                filterObject={filter}
                FilterObjectValueChange={props.FilterObjectValueChange}
                countries={props.countries}
                states={props.states}
                coursesDropDown={props.coursesDropDown}
                facilitiesDropDown={props.facilitiesDropDown}
              />
            ))}
        </div>
      )}
      {props.selectedReportType !== 1 && props.selectedReportType !== 5 ? (
        <div className={"buttonContainer"}>
          <div className="emailEntry">
            <div>Email:</div>
            <input
              type="text"
              value={props.sendEmail}
              onChange={ChangeSendEmail}
            />
          </div>
          {props.selectedReportType === 2 ? (
            <>
              <div
                className={
                  "button" + (props.disableSelectionButtons ? " disabled" : "")
                }
                onClick={
                  props.disableSelectionButtons ? null : SendEnvelopeReprintCSV
                }
              >
                <div className="buttonText">
                  {props.sendingEmail.includes("EnvelopeReprint") ? (
                    <Icon
                      icon="line-md:loading-loop"
                      className="loadingSpinner"
                    />
                  ) : (
                    "Email Envelope Reprint CSV"
                  )}
                </div>
              </div>
              <div
                className={
                  "button" + (props.disableSelectionButtons ? " disabled" : "")
                }
                onClick={props.disableSelectionButtons ? null : ClearSelections}
              >
                <div className="buttonText">Clear Selection</div>
              </div>
            </>
          ) : (
            <>
              <div></div>
              <div></div>
            </>
          )}
          <div
            className="button"
            onClick={props.people?.length > 0 ? ButtonAction : null}
          >
            <div className="buttonText">
              {props.sendingEmail.includes(props.selectedReportType) ? (
                <Icon icon="line-md:loading-loop" className="loadingSpinner" />
              ) : (
                "Send CSV Email"
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Filters;
