import React, { Fragment, useState } from "react";
import axios from "axios";
import * as Constants from "./../../Constants";
import * as Utilities from "./../../Utilities";
import ModalDatafield from "./ModalDatafield";
import SedationModal from "./SedationModal";

function PersonModal(props) {
  const [editableFields, SetEditableFields] = useState({
    sedationFee: props.person.Sedations[0]?.Fee,
    sedationDiscountAmount: props.person.Sedations[0]?.DiscountAmount,
    sedationRefund: props.person.Sedations[0]?.Refund,
    sedationPurchaseDate: props.person.Sedations[0]?.PurchaseDate,
    certificationComments: props.person.Certification?.Comments,
    aamsnNoAamsn: props.person.Aamsn?.NoAamsn,
    aamsnExpirationDate: props.person.Aamsn?.MembershipExpiration,
    aamsnPacketSent: props.person.Aamsn?.AamsnPacketSent,
    aamsnComments: props.person.Aamsn?.Comments,
    sedationComments: props.person.Sedations[0].Comments,
    hold: props.person.Certification?.Hold,
  });

  const [newSedationModalOpen, SetSedationModalOpen] = useState(false);
  const [newSedationEditing, SetNewSedationEditing] = useState(true);

  function ToggleSedationModal() {
    if (newSedationModalOpen && newSedationEditing) {
      if (
        window.confirm("This will cancel the new sedation and close the modal")
      ) {
        SetSedationModalOpen(!newSedationModalOpen);
        SetNewSedationEditing(false);
      }
    } else {
      SetNewSedationEditing(!newSedationModalOpen);
      SetSedationModalOpen(!newSedationModalOpen);
    }
  }

  function ToggleEditMode(e, forcedValue = null) {
    resetFields();
    props.ToggleEditing(forcedValue);
  }

  function HandleChange(e) {
    SetEditableFields({
      ...editableFields,
      [e.target.id]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  }

  function HandleSentButton(e) {
    if (
      window.confirm("This will reset any changes and save the current date")
    ) {
      resetFields();
      UpdatePerson(e.target.id);
    }
  }

  function HandleManualRecertButton(e) {
    ToggleSedationModal();
  }

  function IsManualRecertNeeded(expirationDateString) {
    if (expirationDateString === null || expirationDateString === "") {
      return false;
    }
    let today = new Date();
    let sixMonthsFromNow = today.setMonth(today.getMonth() + 6);
    let expirationDate = new Date(Date.parse(expirationDateString));
    return expirationDate < sixMonthsFromNow;
  }

  function resetFields() {
    SetEditableFields({
      sedationFee: props.person.Sedations[0].Fee,
      sedationDiscountAmount: props.person.Sedations[0].DiscountAmount,
      sedationRefund: props.person.Sedations[0].Refund,
      sedationPurchaseDate:
        Utilities.BuildISODate(props.person.Sedations[0].PurchaseDate) ?? "",
      certificationComments: props.person.Certification?.Comments,
      aamsnNoAamsn: props.person.Aamsn?.NoAamsn,
      aamsnExpirationDate:
        Utilities.BuildISODate(props.person.Aamsn?.MembershipExpiration) ?? "",
      aamsnPacketSent:
        Utilities.BuildISODate(props.person.Aamsn?.AamsnPacketSent) ?? "",
      aamsnComments: props.person.Aamsn?.Comments,
      sedationComments: props.person.Sedations[0].Comments,
      hold: props.person.Certification?.Hold,
    });
  }

  async function UpdatePerson(sentDateToUpdate = null) {
    let formData = new FormData();
    formData.append("Id", props.person.Id ?? "");
    if (sentDateToUpdate != null) {
      let today = new Date();
      formData.append(sentDateToUpdate, today.toISOString());
      if (sentDateToUpdate === "sedation.certificateSentDate") {
        formData.append("sedation.id", props.person.Sedations[0].Id ?? "");
      }
    } else {
      formData.append("sedation.id", props.person.Sedations[0].Id ?? "");
      formData.append("sedation.fee", editableFields.sedationFee ?? "");
      formData.append(
        "sedation.discountAmount",
        editableFields.sedationDiscountAmount ?? ""
      );
      formData.append("sedation.refund", editableFields.sedationRefund ?? "");
      formData.append(
        "sedation.purchaseDate",
        editableFields.sedationPurchaseDate ?? ""
      );
      formData.append(
        "certification.comments",
        editableFields.certificationComments ?? ""
      );
      formData.append("aamsn.comments", editableFields.aamsnComments ?? "");
      formData.append(
        "sedation.comments",
        editableFields.sedationComments ?? ""
      );
      formData.append("aamsn.noaamsn", editableFields.aamsnNoAamsn ?? "");
      formData.append(
        "aamsn.membershipExpiration",
        editableFields.aamsnExpirationDate ?? ""
      );
      formData.append("certification.hold", editableFields.hold ?? "");
      formData.append("certification.recert", editableFields.recert ?? "");
      formData.append(
        "certification.expirationDate",
        editableFields.certificateExpirationDate ?? ""
      );
    }
    let errorMessage = "";
    await axios({
      method: "post",
      url: Constants.API_URL + "Update" + Constants.API_Key,
      headers: {
        UpdateType: "Person",
      },
      data: formData,
    }).catch((error) => {
      errorMessage = error.response.data;
      console.log(errorMessage);
      alert("Error updating person");
    });
    if (errorMessage === "") {
      if (sentDateToUpdate === null || !props.isEditing) {
        props.RefreshPerson(props.person.Id);
        props.RefreshTodo();
        props.ToggleEditing(false);
      }
      return true;
    }

    alert("Error updating person");
    return false;
  }

  return (
    <div className="modal">
      <div className="modalOverlay" onClick={props.CloseModal}></div>
      <div className="modalContent">
        <div className="modalHeader">
          <div className="spacerDiv"></div>
          <div className="buttonContainer modal">
            <div></div>
            <div
              className="modalButton button"
              onClick={
                props.isEditing
                  ? () => {
                      UpdatePerson();
                    }
                  : ToggleEditMode
              }
            >
              {props.isEditing ? "Save" : "Edit"}
            </div>
            {props.isEditing ? (
              <div className="modalButton button" onClick={ToggleEditMode}>
                Cancel
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <span className="close" onClick={props.CloseModal}>
            &times;
          </span>
        </div>
        <div className="modalBody">
          <section>
            <div className="modalBodyHeader">
              <h2>Personal Information</h2>
              <hr />
            </div>
            <div className="modalBodyContent">
              <ModalDatafield
                label="First Name"
                value={props.person.FirstName}
                type="text"
              />
              <ModalDatafield
                label="Last Name"
                value={props.person.LastName}
                type="text"
              />
              <ModalDatafield label="Title" value={props.person.Title} />
            </div>
          </section>
          <section>
            <div className="modalBodyContent">
              <ModalDatafield
                label="Address"
                value={
                  props.person.Address1 +
                  (props.person.Address2 !== null
                    ? " " + props.person.Address2
                    : "")
                }
              />
              <ModalDatafield label="City" value={props.person.City} />
              <ModalDatafield label="State" value={props.person.State.Name} />

              <ModalDatafield label="Zip Code" value={props.person.Zip} />
              <ModalDatafield
                label="Country"
                value={props.person.Country.Name}
              />
              <div></div>
              <ModalDatafield label="Email" value={props.person.Email} />
              <ModalDatafield label="Phone" value={props.person.Phone} />
            </div>
          </section>
          <section>
            <h2>Certification Information</h2>
            <hr />
            <div className="modalBodyContent">
              <ModalDatafield
                type="date"
                label="Original Certification Date"
                value={props.person.Certification?.OriginalCertificationDate}
                editable={false}
              />
              <ModalDatafield
                label="Certification Active"
                editable={false}
                type="text"
                value={props.person.Certification?.Active ? "Yes" : "No"}
              />
              <ModalDatafield
                label="State Required Certification"
                editable={false}
                type="text"
                value={props.person.Certification?.StateRequired ? "Yes" : "No"}
              />
              <ModalDatafield
                label="License Number"
                editable={false}
                type="text"
                value={
                  props.person.Licenses !== null &&
                  props.person.Licenses.sort((a, b) => b.Id - a.Id)[0]
                    .LicenseNumber !== ""
                    ? props.person.Licenses.sort((a, b) => b.Id - a.Id)[0]
                        .LicenseNumber
                    : "No License Number"
                }
              />
              <ModalDatafield
                type="checkbox"
                label="On Hold"
                inputId="hold"
                value={editableFields.hold}
                HandleChange={HandleChange}
                editable={true}
                isEditing={props.isEditing}
              />
              <ModalDatafield
                type="checkbox"
                label="Recertification"
                inputId="recert"
                value={props.person.Sedations?.length > 1}
                HandleChange={HandleChange}
                editable={true}
                isEditing={props.isEditing}
              />
              <ModalDatafield
                type="certificateExpirationDate"
                label="Expiration Date"
                fullLength={true}
                editable={true}
                isEditing={props.isEditing}
                value={props.person.Certification?.ExpirationDate}
                manualRecert={IsManualRecertNeeded(
                  props.person.Certification?.ExpirationDate
                )}
                HandleChange={HandleManualRecertButton}
                inputId="certificateExpirationDate"
              />
              <ModalDatafield
                inputId="certificationComments"
                label="Comments"
                editable={true}
                isEditing={props.isEditing}
                type="textarea"
                fullLength={true}
                value={editableFields.certificationComments}
                HandleChange={HandleChange}
                placeholderValue={
                  editableFields.certificationComments !== null
                    ? editableFields.certificationComments
                    : props.person.Certification?.Comments
                }
              />
            </div>
          </section>
          <section>
            <h2>AAMSN Membership</h2>
            <hr />
            <div className="modalBodyContent">
              <ModalDatafield
                label="AAMSN Member ID"
                value={
                  props.person.Aamsn?.AamsnId !== null &&
                  props.person.Aamsn?.AamsnId !== 0
                    ? props.person.Aamsn?.AamsnId
                    : "No Member ID"
                }
                type="text"
                editable={false}
              />
              <ModalDatafield
                label="Active Member"
                editable={false}
                type="text"
                value={props.person.Aamsn?.ActiveMember ? "Yes" : "No"}
              />
              <ModalDatafield
                label="Member Since"
                editable={false}
                type="text"
                value={
                  props.person.Aamsn?.MemberSince !== null &&
                  props.person.Aamsn?.MemberSince !== 0
                    ? props.person.Aamsn?.MemberSince
                    : "No Member Since"
                }
              />
              <ModalDatafield
                label="Membership Expiration"
                inputId="aamsnExpirationDate"
                editable={true}
                type="date"
                isEditing={props.isEditing}
                HandleChange={HandleChange}
                value={editableFields.aamsnExpirationDate}
              />
              <ModalDatafield
                label="Dues Frequency"
                editable={false}
                type="text"
                value={props.person.Aamsn?.DuesFrequency.Name}
              />
              <ModalDatafield
                label="No Aamsn"
                editable={true}
                type="checkbox"
                value={editableFields.aamsnNoAamsn}
                isEditing={props.isEditing}
                HandleChange={HandleChange}
                inputId="aamsnNoAamsn"
              />
              <ModalDatafield
                label="Aamsn Packet Sent"
                type="sentButton"
                HandleChange={HandleSentButton}
                value={
                  props.person.Aamsn?.AamsnPacketSent
                    ? Utilities.ToLocalDateStringNoTimezone(
                        props.person.Aamsn?.AamsnPacketSent
                      )
                    : null
                }
                inputId="aamsn.aamsnPacketSent"
                highlighted={
                  !props.person.Aamsn?.AamsnPacketSent != null &&
                  props.person.Aamsn?.AamsnPacketSent !== ""
                }
                forceShow={true}
              />
              <ModalDatafield
                label="Comments"
                inputId="aamsnComments"
                editable={true}
                type="textarea"
                fullLength={true}
                isEditing={props.isEditing}
                placeholderValue={
                  editableFields.aamsnComments !== null
                    ? editableFields.aamsnComments
                    : props.person.Aamsn?.Comments
                }
                value={editableFields.aamsnComments}
                HandleChange={HandleChange}
              />
            </div>
          </section>
          <section>
            <div className="modalBodyHeader">
              <h2>Sedation Information</h2>
              <hr />
            </div>
            <div className="modalBodyContent">
              <ModalDatafield
                label="Edition"
                type="text"
                value={props.person.Sedations[0].Edition}
              />
              <ModalDatafield
                label="Fee"
                inputId="sedationFee"
                type="number"
                placeholderValue={+props.person.Sedations[0].Fee}
                editable={true}
                isEditing={props.isEditing}
                HandleChange={HandleChange}
                value={editableFields.sedationFee}
              />
              <ModalDatafield
                label="Discount Amount"
                inputId="sedationDiscountAmount"
                type="number"
                placeholderValue={+props.person.Sedations[0].DiscountAmount}
                editable={true}
                isEditing={props.isEditing}
                HandleChange={HandleChange}
                value={editableFields.sedationDiscountAmount}
              />
              <ModalDatafield
                label="Refund"
                inputId="sedationRefund"
                type="number"
                placeholderValue={+props.person.Sedations[0].Refund}
                editable={true}
                isEditing={props.isEditing}
                HandleChange={HandleChange}
                value={editableFields.sedationRefund}
              />
              <ModalDatafield
                label="Purchase Date"
                inputId="sedationPurchaseDate"
                type="date"
                value={editableFields.sedationPurchaseDate}
                HandleChange={HandleChange}
                editable={true}
                isEditing={props.isEditing}
                highlighted={
                  (props.person.Sedations[0].PurchaseDate == null ||
                    props.person.Sedations[0].PurchaseDate === "") &&
                  props.person.FacilityId === 0
                }
              />
              <ModalDatafield
                type="text"
                label="Completed"
                value={
                  props.person.Sedations[0].SedationCompleted ? "Yes" : "No"
                }
                editable={false}
              />
              <ModalDatafield
                label="Passed"
                value={props.person.Sedations[0].Passed ? "Yes" : "No"}
                editable={false}
                type="text"
              />
              <ModalDatafield
                label="Type"
                value={props.person.Sedations[0].SedationType.Name}
                editable={false}
                type="text"
              />
              <ModalDatafield
                label="Facility Name"
                editable={false}
                type="text"
                value={props.person.Sedations[0].Facility?.Name ?? ""}
              />
              <ModalDatafield
                label="Address"
                editable={false}
                type="text"
                value={
                  props.person.Sedations[0].Facility?.Address1 +
                  " " +
                  (props.person.Sedations[0].Facility?.Address2
                    ? props.person.Sedations[0].Facility?.Address2
                    : "")
                }
              />
              {props.person.Sedations[0].SedationType.Name === "FACILITY" && (
                <>
                  <ModalDatafield
                    label="City"
                    editable={false}
                    type="text"
                    value={props.person.Sedations[0].Facility?.City}
                  />
                  <ModalDatafield
                    label="State"
                    editable={false}
                    type="text"
                    value={props.person.Sedations[0].Facility?.State?.Name}
                  />
                  <ModalDatafield
                    label="Zip Code"
                    editable={false}
                    type="text"
                    value={props.person.Sedations[0].Facility?.Zip}
                  />
                </>
              )}
              <ModalDatafield
                label="Test Received On"
                type="date"
                editable={false}
                value={
                  props.person.Sedations[0]?.TestReceivedDate !== null &&
                  props.person.Sedations[0]?.TestReceivedDate !== 0
                    ? Utilities.ToLocalDateStringNoTimezone(
                        props.person.Sedations[0].TestReceivedDate
                      )
                    : ""
                }
              />
              <ModalDatafield
                label="Certificate Sent On"
                inputId="sedation.certificateSentDate"
                type="sentButton"
                value={props.person.Sedations[0]?.CertificateSentDate}
                HandleChange={HandleSentButton}
                highlighted={
                  !props.person.Sedations?.some(
                    (s) =>
                      s.CertificateSentDate != null &&
                      s.CertificateSentDate !== ""
                  )
                }
              />
              <div></div>
              <ModalDatafield
                label="Comments"
                editable={true}
                isEditing={props.isEditing}
                type="textarea"
                fullLength={true}
                inputId="sedationComments"
                placeholderValue={
                  editableFields.sedationComments !== null
                    ? editableFields.sedationComments
                    : props.person.Sedations[0].Comments
                }
                value={editableFields.sedationComments}
                HandleChange={HandleChange}
              />
            </div>
          </section>
          {props.person.Sedations.length > 1 ? (
            <section>
              <div className="modalBodyHeader">
                <hr />
                <h2>Older Sedations</h2>
              </div>
              {props.person.Sedations.slice(1).map((sedation, index) => (
                <Fragment key={index}>
                  <hr />
                  <div className="modalBodyContent">
                    <ModalDatafield
                      label="Edition"
                      type="text"
                      value={sedation.Edition}
                    />
                    <ModalDatafield
                      label="Fee"
                      inputId="sedationFee"
                      type="number"
                      placeholderValue={+sedation.Fee}
                      HandleChange={HandleChange}
                      value={+sedation.Fee}
                    />
                    <ModalDatafield
                      label="Discount Amount"
                      inputId="sedationDiscountAmount"
                      type="number"
                      placeholderValue={+sedation.DiscountAmount}
                      HandleChange={HandleChange}
                      value={+sedation.DiscountAmount}
                    />
                    <ModalDatafield
                      label="Refund"
                      inputId="sedationRefund"
                      type="number"
                      placeholderValue={+sedation.Refund}
                      HandleChange={HandleChange}
                      value={sedation.Refund}
                    />
                    <ModalDatafield
                      label="Purchase Date"
                      inputId="sedationPurchaseDate"
                      type="date"
                      value={sedation.PurchaseDate}
                      HandleChange={HandleChange}
                      highlighted={
                        (sedation.PurchaseDate == null ||
                          sedation.PurchaseDate === "") &&
                        props.person.FacilityId === 0
                      }
                    />
                    <ModalDatafield
                      type="text"
                      label="Completed"
                      value={sedation.SedationCompleted ? "Yes" : "No"}
                      editable={false}
                    />
                    <ModalDatafield
                      label="Passed"
                      value={sedation.Passed ? "Yes" : "No"}
                      editable={false}
                      type="text"
                    />
                    <ModalDatafield
                      label="Type"
                      value={sedation.SedationType.Name}
                      editable={false}
                      type="text"
                    />
                    <ModalDatafield
                      label="Facility Name"
                      editable={false}
                      type="text"
                      value={sedation.Facility?.Name ?? ""}
                    />
                    <ModalDatafield
                      label="Address"
                      editable={false}
                      type="text"
                      value={
                        sedation.Facility?.Address1 +
                        " " +
                        (sedation.Facility?.Address2
                          ? sedation.Facility?.Address2
                          : "")
                      }
                    />
                    {sedation.SedationType.Name === "FACILITY" && (
                      <>
                        <ModalDatafield
                          label="City"
                          editable={false}
                          type="text"
                          value={sedation.Facility?.City}
                        />
                        <ModalDatafield
                          label="State"
                          editable={false}
                          type="text"
                          value={sedation.Facility?.State?.Name}
                        />
                        <ModalDatafield
                          label="Zip Code"
                          editable={false}
                          type="text"
                          value={sedation.Facility?.Zip}
                        />
                      </>
                    )}
                    <ModalDatafield
                      label="Test Received On"
                      type="date"
                      editable={false}
                      value={
                        sedation.TestReceivedDate !== null &&
                        sedation.TestReceivedDate !== 0
                          ? Utilities.ToLocalDateStringNoTimezone(
                              sedation.TestReceivedDate
                            )
                          : ""
                      }
                    />
                    <ModalDatafield
                      label="Certificate Sent On"
                      inputId="certificateSentDate"
                      type="date"
                      value={sedation.CertificateSentDate}
                      highlighted={
                        !props.person.Sedations?.some(
                          (s) =>
                            s.CertificateSentDate != null &&
                            s.CertificateSentDate !== ""
                        )
                      }
                    />
                    <div></div>
                    <ModalDatafield
                      label="Comments"
                      type="textarea"
                      fullLength={true}
                      inputId="sedationComments"
                      placeholderValue={sedation.Comments}
                      value={sedation.Comments}
                    />
                  </div>
                </Fragment>
              ))}
            </section>
          ) : null}
        </div>
      </div>
      {newSedationModalOpen ? (
        <SedationModal
          person={props.person}
          ToggleSedationModal={ToggleSedationModal}
          isEditing={newSedationEditing}
          SetNewSedationEditing={SetNewSedationEditing}
          Refresh={props.Refresh}
          RefreshPerson={props.RefreshPerson}
          RefreshTodo={props.RefreshTodo}
          facilities={props.facilities.filter(
            (f) =>
              f.AdditionalInfo.trim() !== "," ||
              f.Id === props.person.FacilityId
          )}
          ToggleEditing={props.ToggleEditing}
          CloseModal={ToggleSedationModal}
        />
      ) : null}
    </div>
  );
}

export default PersonModal;
