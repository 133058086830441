export function ToLocalDateStringNoTimezone(dateString) {
  let date = new Date(Date.parse(dateString));
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  return `${month}/${day}/${year}`;
}

export function BuildISODate(date) {
  let tempDate = new Date(Date.parse(date));
  let newString =
    tempDate.getFullYear() +
    "-" +
    (tempDate.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    tempDate.getDate().toString().padStart(2, "0") +
    "T00:00:00";
  return newString;
}
