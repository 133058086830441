import React, { forwardRef } from "react";

function Facility(props, ref) {
  function handleClick() {
    props.OpenModal(props.facility);
  }

  return (
    <div
      className="selectableRow resultItem facility"
      onClick={handleClick}
      ref={props.index === props.page * 50 - 10 || props.lastItem ? ref : null}
    >
      <div className="name bold">F</div>
      <div className="name facility">{props.facility.Name}</div>
    </div>
  );
}
export default forwardRef(Facility);
