import * as Constants from "./Components/Constants";

export const msalConfig = {
  auth: {
    clientId: "d4b965e3-9173-46fb-81f2-76a688a0ba6e",
    // authority: "https://login.microsoftonline.com/common",
    authority:
      "https://login.microsoftonline.com/3ed3bcb2-cab0-4ea7-84b4-ef1384d7c9d8",
    redirectUri: Constants.redirectUri,
    postLogoutRedirectUri: Constants.redirectUri
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
