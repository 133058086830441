import React, { forwardRef } from "react";
import * as Utilities from "../../Utilities";

function Course(props, ref) {
  function handleClick() {
    props.OpenModal(props.course);
  }

  return (
    <div
      className="selectableRow resultItem course"
      onClick={handleClick}
      ref={props.index === props.page * 50 - 10 || props.lastItem ? ref : null}
    >
      <div className="name bold">C</div>
      <div className="name course">{props.course.Name}</div>
      <div className="name course">{props.course.Facility.Name}</div>
      <div className="name course">{props.course.Code}</div>
      <div className="name course">
        {Utilities.ToLocalDateStringNoTimezone(props.course.StartDate)}
      </div>
    </div>
  );
}
export default forwardRef(Course);
