export const API_URL = "https://healthyvisions-fnc.azurewebsites.net/api/";
// export const API_URL = "http://localhost:7071/api/";
export const API_Key =
  "?code=2NjZbGH0c-_H4tnuMf0b0iFvG_z0n4VclHaZnZVYWRCMAzFu6CXqkQ==";

export const redirectUri = window.location.href.includes("localhost")
  ? "http://localhost:3000"
  : window.location.href.includes("gentle-forest")
  ? "https://gentle-forest-05a869f0f.3.azurestaticapps.net"
  : "https://reports.sedationcertification.com";

// used for passing to the country dropdown menu
export const countries = [
  { id: 1, name: "United States" },
  { id: 2, name: "United States Territories" },
  { id: 3, name: "Canada" },
  { id: 4, name: "Mexico" },
  { id: 5, name: "Costa Rica" },
];

// used for passing into the Licesnse State dropdown menu
export const states = [
  { id: 2, abbreviation: "AL", name: "Alabama", country: 1 },
  { id: 3, abbreviation: "AK", name: "Alaska", country: 1 },
  { id: 4, abbreviation: "AZ", name: "Arizona", country: 1 },
  { id: 5, abbreviation: "AR", name: "Arkansas", country: 1 },
  { id: 6, abbreviation: "CA", name: "California", country: 1 },
  { id: 7, abbreviation: "CO", name: "Colorado", country: 1 },
  { id: 8, abbreviation: "CT", name: "Connecticut", country: 1 },
  { id: 9, abbreviation: "DE", name: "Delaware", country: 1 },
  { id: 10, abbreviation: "DC", name: "District Of Columbia", country: 1 },
  { id: 11, abbreviation: "FL", name: "Florida", country: 1 },
  { id: 12, abbreviation: "GA", name: "Georgia", country: 1 },
  { id: 13, abbreviation: "HI", name: "Hawaii", country: 1 },
  { id: 14, abbreviation: "ID", name: "Idaho", country: 1 },
  { id: 15, abbreviation: "IL", name: "Illinois", country: 1 },
  { id: 16, abbreviation: "IN", name: "Indiana", country: 1 },
  { id: 17, abbreviation: "IA", name: "Iowa", country: 1 },
  { id: 18, abbreviation: "KS", name: "Kansas", country: 1 },
  { id: 19, abbreviation: "KY", name: "Kentucky", country: 1 },
  { id: 20, abbreviation: "LA", name: "Louisiana", country: 1 },
  { id: 21, abbreviation: "ME", name: "Maine", country: 1 },
  { id: 22, abbreviation: "MD", name: "Maryland", country: 1 },
  { id: 23, abbreviation: "MA", name: "Massachusetts", country: 1 },
  { id: 24, abbreviation: "MI", name: "Michigan", country: 1 },
  { id: 25, abbreviation: "MN", name: "Minnesota", country: 1 },
  { id: 26, abbreviation: "MS", name: "Mississippi", country: 1 },
  { id: 27, abbreviation: "MO", name: "Missouri", country: 1 },
  { id: 28, abbreviation: "MT", name: "Montana", country: 1 },
  { id: 29, abbreviation: "NE", name: "Nebraska", country: 1 },
  { id: 30, abbreviation: "NV", name: "Nevada", country: 1 },
  { id: 31, abbreviation: "NH", name: "New Hampshire", country: 1 },
  { id: 32, abbreviation: "NJ", name: "New Jersey", country: 1 },
  { id: 33, abbreviation: "NM", name: "New Mexico", country: 1 },
  { id: 34, abbreviation: "NY", name: "New York", country: 1 },
  { id: 35, abbreviation: "NC", name: "North Carolina", country: 1 },
  { id: 36, abbreviation: "ND", name: "North Dakota", country: 1 },
  { id: 37, abbreviation: "OH", name: "Ohio", country: 1 },
  { id: 38, abbreviation: "OK", name: "Oklahoma", country: 1 },
  { id: 39, abbreviation: "OR", name: "Oregon", country: 1 },
  { id: 40, abbreviation: "PA", name: "Pennsylvania", country: 1 },
  { id: 41, abbreviation: "PR", name: "Puerto Rico", country: 1 },
  { id: 42, abbreviation: "RI", name: "Rhode Island", country: 1 },
  { id: 43, abbreviation: "SC", name: "South Carolina", country: 1 },
  { id: 44, abbreviation: "SD", name: "South Dakota", country: 1 },
  { id: 45, abbreviation: "TN", name: "Tennessee", country: 1 },
  { id: 46, abbreviation: "TX", name: "Texas", country: 1 },
  { id: 47, abbreviation: "UT", name: "Utah", country: 1 },
  { id: 48, abbreviation: "VT", name: "Vermont", country: 1 },
  { id: 49, abbreviation: "VA", name: "Virginia", country: 1 },
  { id: 50, abbreviation: "VI", name: "Virgin Islands", country: 1 },
  { id: 51, abbreviation: "WA", name: "Washington", country: 1 },
  { id: 52, abbreviation: "WV", name: "West Virginia", country: 1 },
  { id: 53, abbreviation: "WI", name: "Wisconsin", country: 1 },
  { id: 54, abbreviation: "WY", name: "Wyoming", country: 1 },
];
