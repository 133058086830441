import React, { Fragment, useEffect, useState, useRef } from "react";
import Person from "./Person";
import Course from "./Course";
import Facility from "./Facility";
import PersonModal from "./PersonModal";
import CourseModal from "./CourseModal";
import FacilityModal from "./FacilityModal";

function ResultList(props) {
  const [isEditing, SetIsEditing] = useState(false);
  const [currentCourse, SetCurrentCourse] = useState(null);
  const [currentFacility, SetCurrentFacility] = useState(null);
  const [loadedList, SetLoadedList] = useState([]);
  const [page, SetPage] = useState(1);
  const resultListDiv = useRef(null);

  const messageBasic =
    "When you click on the desired report, an email will be sent to ";
  const messageSearch =
    "When you click search, a report will be sent via email to ";

  function TogglePersonModal(id = 0) {
    if (isNaN(id)) {
      id = 0;
    }
    props.Refresh(id);
    SetIsEditing(false);
  }

  function ToggleCourseModal(item = null) {
    if (item.target != null) {
      item = null;
    }
    SetCurrentCourse(item);
    SetIsEditing(false);
  }

  function ToggleFacilityModal(item = null) {
    if (item.target != null) {
      item = null;
    }
    SetCurrentFacility(item);
    SetIsEditing(false);
  }

  function ToggleEditing(forcedValue = null) {
    SetIsEditing(forcedValue ?? !isEditing);
  }

  function handleChange(e) {
    props.SetSendEmail(e.target.value);
  }

  function LoadMore() {
    SetPage(page + 1);
  }

  useEffect(() => {
    if (currentCourse != null) {
      SetCurrentCourse(
        props.processedResultList.find((x) => x.Id === currentCourse.Id)
      );
    }
    if (currentFacility != null) {
      SetCurrentFacility(
        props.processedResultList.find((x) => x.Id === currentFacility.Id)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.processedResultList]);

  function OnScroll() {
    if (
      resultListDiv.current != null &&
      resultListDiv.current.scrollTop >
        resultListDiv.current.scrollHeight * 0.95 -
          resultListDiv.current.clientHeight
    ) {
      LoadMore();
    }
  }

  useEffect(() => {
    if (resultListDiv.current != null) {
      resultListDiv.current.scrollTo({ top: 0 });
    }
    SetPage(1);
  }, [props.selectedReportType, props.rerender]);

  useEffect(() => {
    SetLoadedList(props.processedResultList.slice(0, page * 50));
  }, [props.processedResultList, page]);

  return (
    <>
      {[2, 3, 4, 5].includes(props.selectedReportType) ? (
        <div
          className={
            "container results" +
            (props.selectedReportType === 5 ? " todo" : "")
          }
          onScroll={OnScroll}
          ref={resultListDiv}
        >
          {loadedList.length > 0
            ? loadedList.map((item, index) => (
                <Fragment key={index}>
                  {item.FirstName != null ? (
                    <Person
                      person={item}
                      selectedReportType={props.selectedReportType}
                      selectedIds={props.selectedPersonIds}
                      HandleCheckbox={props.HandleCheckbox}
                      OpenModal={TogglePersonModal}
                      index={index}
                      page={page}
                      lastItem={index === loadedList.length - 1}
                    />
                  ) : item.Participated != null ? (
                    <Course
                      course={item}
                      OpenModal={ToggleCourseModal}
                      index={index}
                      page={page}
                      lastItem={index === loadedList.length - 1}
                    />
                  ) : (
                    <Facility
                      facility={item}
                      OpenModal={ToggleFacilityModal}
                      index={index}
                      page={page}
                      lastItem={index === loadedList.length - 1}
                    />
                  )}
                </Fragment>
              ))
            : props.people?.length > 0
            ? "No Results"
            : "Loading Data..."}
        </div>
      ) : (
        <div className="container results message">
          <span>
            {props.selectedReportType === 1 ? messageBasic : messageSearch}
            <input
              type="text"
              placeholder={props.sendEmail}
              value={props.sendEmail}
              onChange={handleChange}
              className="sendEmailInput "
            />
          </span>
        </div>
      )}
      {props.currentPerson != null ? (
        <PersonModal
          isEditing={isEditing}
          ToggleEditing={ToggleEditing}
          person={props.currentPerson}
          CloseModal={TogglePersonModal}
          RefreshPerson={props.Refresh}
          RefreshTodo={props.RefreshTodo}
          facilities={props.facilitiesDropDown}
        />
      ) : null}
      {currentCourse != null ? (
        <CourseModal
          isEditing={isEditing}
          ToggleEditing={ToggleEditing}
          course={currentCourse}
          CloseModal={ToggleCourseModal}
          Refresh={props.Refresh}
          facilities={props.facilitiesDropDown}
          RefreshTodo={props.RefreshTodo}
        />
      ) : null}
      {currentFacility != null ? (
        <FacilityModal
          isEditing={isEditing}
          ToggleEditing={ToggleEditing}
          facility={currentFacility}
          CloseModal={ToggleFacilityModal}
          Refresh={props.Refresh}
          states={props.states}
          RefreshTodo={props.RefreshTodo}
        />
      ) : null}
    </>
  );
}

export default ResultList;
