import React from "react";

function Header() {
  return (
    <div className="mainHeader">
      <div className="logo">
        <img src="/Healthy-Visions-Logo-White-300x300.png" alt="Logo" />
      </div>
      <div className="name header">REPORTING</div>
    </div>
  );
}

export default Header;
